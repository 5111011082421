

async function forceReLogin(username,pwd){
    try {
        /* console.log('el username: ',username)
        console.log('el pwd: ',pwd) */
        return { code: 1,token: 'hola' }
    } catch (error) {
        console.error('ocurrio un error en el forceReLogin')
        return { code: 1 }
    }
}

module.exports = {
    forceReLogin
}