<template>
  <b-container fluid>
    <b-row>
      <b-col class="col-login col-remove"
        ><gif-container></gif-container
      ></b-col>
      <b-col class="col-login" v-if="next == false"
        ><forgot-container
          @SentEmail="nextstep"
          @email="setEmail"
        ></forgot-container
      ></b-col>
      <b-col class="col-login" v-if="next == true"
        ><change-container :email="email"></change-container
      ></b-col>
    </b-row>
  </b-container>
</template>
<script>
import GifContainer from "../login/GIfContainer.vue";
import ForgotContainer from "./ForgotContainer.vue";
import ChangeContainer from "./ChangePassword.vue";
export default {
  name: "MainContainer",
  components: {
    GifContainer,
    ForgotContainer,
    ChangeContainer,
  },
  data() {
    return {
      next: false,
      email: "",
    };
  },
  methods: {
    nextstep(value) {
      if (value) {
        this.next = true;
      }
    },
    setEmail(value) {
      this.email = value;
    },
  },
};
</script>
<style scoped>
.col-login {
  padding: 0px;
}
@media (max-width: 768px) {
  .col-remove {
    display: none;
  }
}
</style>
