import { render, staticRenderFns } from "./MainContainer.vue?vue&type=template&id=b4140abc&scoped=true&"
import script from "./MainContainer.vue?vue&type=script&lang=js&"
export * from "./MainContainer.vue?vue&type=script&lang=js&"
import style0 from "./MainContainer.vue?vue&type=style&index=0&id=b4140abc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4140abc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-login-v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4140abc')) {
      api.createRecord('b4140abc', component.options)
    } else {
      api.reload('b4140abc', component.options)
    }
    module.hot.accept("./MainContainer.vue?vue&type=template&id=b4140abc&scoped=true&", function () {
      api.rerender('b4140abc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/verify/MainContainer.vue"
export default component.exports