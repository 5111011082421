import './set-public-path'
import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import singleSpaVue from 'single-spa-vue';
import i18n from './i18n'
import Vuelidate from 'vuelidate'
import { store, } from "@isc/styleguide";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


Vue.config.productionTip = false;
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
//Vue.use(router)
//Vue.use(i18n)
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    el :"#login",
    router,
    i18n,
    store
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

export { default as forceReLogin } from "./util/export.login";
