var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "", id: "footer" } },
    [
      _c(
        "b-container",
        { attrs: { id: "footer-information", fluid: "" } },
        [
          _c(
            "b-container",
            { staticClass: "footer-container" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "col-email col-nav-cell",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c("img", {
                        staticClass: "logo-navbar",
                        staticStyle: { "margin-bottom": "30px" },
                        attrs: { src: require("../../public/images/logo.png") }
                      }),
                      _c("div", [_vm._v("Simple, Inteligente")]),
                      _c("div", [_vm._v("sales@isocialcube.com")])
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "col-info col-nav-cell",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                        _c("div", [
                          _vm._v(
                            "Av. Benavides 1238, Miraflores, Oficina No. 301"
                          )
                        ]),
                        _c("div", [_vm._v("Miraflores / Lima - Perú")]),
                        _c("div", [_vm._v("+51 1 2422786")])
                      ]),
                      _c("div", [
                        _c("div", [
                          _vm._v("6001 W Parmer Ln Ste 370 PMB 5987")
                        ]),
                        _c("div", [_vm._v("Austin, TX 78727, EE. UU.")]),
                        _c("div", [_vm._v("+1 512 994 1015")])
                      ])
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "col-rrss col-nav-cell",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c(
                        "b-container",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                "margin-right": "50px"
                              }
                            },
                            [_vm._v("Siguenos en:")]
                          ),
                          _c(
                            "div",
                            [
                              _c("b-icon-facebook", {
                                staticClass: "icon-footer"
                              }),
                              _c(
                                "b-icon-instagram",
                                { staticClass: "icon-footer" },
                                [_vm._v("/")]
                              ),
                              _c("b-icon-linkedin", {
                                staticClass: "icon-footer"
                              }),
                              _c("b-icon-youtube", {
                                staticClass: "icon-footer"
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-container", { attrs: { id: "footer-rights", fluid: "" } }, [
        _c("span", [_vm._v("@2021 ANGEL ORELLANA")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }