var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "container-register", attrs: { fluid: "" } },
    [
      _c("img", {
        staticClass: "image-register",
        attrs: { src: require("../../../public/images/register-opt.jpg") }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }