var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { staticClass: "nav-bar", attrs: { sticky: "" } },
    [
      _c(
        "b-container",
        { staticClass: "nav-container", attrs: { "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "navbar-rigth" },
            [
              _c("b-navbar-brand", { attrs: { href: "/login" } }, [
                _c("img", {
                  staticClass: "logo-navbar",
                  attrs: {
                    width: 36,
                    height: 42,
                    src: require("../assets/isc_logo.svg")
                  }
                })
              ]),
              _vm.showLoginButton
                ? _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/login/register" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "button-nav",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.showRegister()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("navbar.login")))]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showRegisterButton
                ? _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/login" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "button-nav",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.showLogin()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("navbar.register")))]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-nav-item",
                [
                  _c(
                    "b-nav-text",
                    {
                      staticClass: "change-lang",
                      attrs: { id: "es-selector" },
                      on: {
                        click: function($event) {
                          return _vm.selectLang("es")
                        }
                      }
                    },
                    [_vm._v("ES")]
                  )
                ],
                1
              ),
              _c(
                "b-nav-item",
                [
                  _c("b-nav-text", { staticClass: "lang-separator" }, [
                    _vm._v("|")
                  ])
                ],
                1
              ),
              _c(
                "b-nav-item",
                [
                  _c(
                    "b-nav-text",
                    {
                      staticClass: "change-lang",
                      attrs: { id: "en-selector" },
                      on: {
                        click: function($event) {
                          return _vm.selectLang("en")
                        }
                      }
                    },
                    [_vm._v("EN")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }