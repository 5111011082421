var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "container-forgot-inner", attrs: { fluid: "" } },
        [
          _c("div", { staticClass: "title-forgot" }, [
            _vm._v(_vm._s(_vm.$t("forgot.sentmail.tittle")))
          ]),
          _c("div", { staticClass: "subtitle-forgot" }, [
            _vm._v(_vm._s(_vm.$t("forgot.sentmail.subtitle")))
          ]),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.sentmail()
                }
              }
            },
            [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  class: ["input-text"],
                  attrs: {
                    id: "email",
                    name: "email",
                    type: "text",
                    placeholder: _vm.$t("forgot.sentmail.form.email")
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                })
              ]),
              _vm.forgotPressed && !_vm.$v.email.required
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("forgot.sentmail.form.emailRequired")) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  { staticClass: "button-forgot", attrs: { type: "submit" } },
                  [
                    _vm.loading
                      ? _c(
                          "span",
                          [
                            _c("b-spinner", { staticClass: "spinner-btn" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("register.form.load")) + " "
                            )
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("forgot.sentmail.form.check")) +
                              " "
                          )
                        ])
                  ]
                )
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "container-buttom-text" },
            [
              _vm._v(" " + _vm._s(_vm.$t("register.msg1")) + " "),
              _c(
                "router-link",
                {
                  staticClass: "link-bottom",
                  staticStyle: { "text-decoration": "underline" },
                  attrs: { to: "/login" }
                },
                [_vm._v(_vm._s(_vm.$t("register.link1")))]
              )
            ],
            1
          )
        ]
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true
        },
        on: { sendResult: _vm.forgotConfirm },
        model: {
          value: _vm.showAlert,
          callback: function($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }