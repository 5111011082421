<template>
  <div>
    <b-container fluid class="container-forgot-inner">
      <div class="title-forgot">{{ $t("forgot.sentmail.tittle") }}</div>
      <div class="subtitle-forgot">{{ $t("forgot.sentmail.subtitle") }}</div>
      <form action="" @submit.prevent="sentmail()">
        <div>
          <input
            id="email"
            name="email"
            type="text"
            :class="['input-text']"
            v-model="email"
            :placeholder="$t('forgot.sentmail.form.email')"
          />
        </div>
        <div class="error" v-if="forgotPressed && !$v.email.required">
          {{ $t("forgot.sentmail.form.emailRequired") }}
        </div>
        <div class="button-container">
          <button type="submit" class="button-forgot">
            <span v-if="loading">
              <b-spinner class="spinner-btn"></b-spinner>
              {{ $t("register.form.load") }}
            </span>
            <span v-else>
              {{ $t("forgot.sentmail.form.check") }}
            </span>
          </button>
        </div>
      </form>
      <div class="container-buttom-text">
        {{ $t("register.msg1") }}
        <router-link
          to="/login"
          style="text-decoration: underline"
          class="link-bottom"
          >{{ $t("register.link1") }}</router-link
        >
      </div>
    </b-container>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="forgotConfirm"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import {
  AlertSetting,
  IscModalAlerts,
  mapActions,
} from "@isc/styleguide";

export default {
  name: "ForgotContainer",
  mixins: [AlertSetting],
  components: {
    IscModalAlerts
  },
  data() {
    return {
      email: "",
      code: "",
      forgotPressed: false,
      sent: true,
      isForgot: false,
      loading: false
    };
  },
  mounted() {
    if (window.innerWidth >= 768) {
      const heightContainer = window.innerHeight - 62;
      const $registerContainer = document.querySelector(
        ".container-forgot-inner"
      );
      $registerContainer.style.height = String(heightContainer) + "px";
    }
  },
  methods: {
    ...mapActions({
      //getInfoUser:"settings/profile/GET_INFO_USER",
      forgotPassword: "login/auth/FORGOT_PASSWORD"
    }),
    async sentmail() {
      this.forgotPressed = true;
      if (this.$v.email.$invalid) {
        return;
      }
      try {
        this.loading = true;
        // validation if User exists 
        this.email = this.email.trim();
        
        await this.forgotPassword({ email: this.email });

        this.isForgot = true;
        this.successTitleModal("Email sent!","Check your mail.");
      } catch (error) {
        console.log("[Error] in function sentMail ", error);
        this.errorModal(error.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    forgotConfirm(value ){
      this.showAlert = value;
      if(this.isForgot){
        this.isForgot = false;
        this.$emit("SentEmail", this.sent);
        this.$emit("email", this.email);
      }
    },
  },
  validations: {
    email: { required },
  },
};
</script>
<style scoped>
.container-forgot-inner {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title-forgot {
  font-size: 52.5px;
  font-weight: 200;
  text-align: center;
}
.subtitle-forgot {
  font-size: 16px;
  font-weight: 200;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.input-text {
  width: 50%;
  margin-left: 25%;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 15px 25px;
}
.button-container {
  text-align: center;
}
.button-forgot {
  width: 50%;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: rgb(0, 206, 193);
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
}
.button-forgot:hover {
  background-color: rgb(3, 189, 177);
}
.error {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #f44336;
}
.container-buttom-text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
}
.link-bottom {
  color: black;
}
</style>
