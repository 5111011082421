<template>
  <div>
    <b-container fluid class="container-verify-inner">
      <div class="title-verify">{{ $t("verify.title") }}</div>
      <div class="subtitle-verify">{{ $t("verify.subtitle") }}</div>
      <form action="" @submit.prevent="validateCode()">
        <div>
          <input
            type="text"
            class="input-text"
            v-model="email"
            :placeholder="$t('verify.form.email')"
          />
        </div>
        <div class="error" v-if="!$v.email.required && verifyPressed">
          {{ $t("verify.form.emailRequired") }}
        </div>
        <div>
          <input
            type="text"
            class="input-text"
            v-model="code"
            :placeholder="$t('verify.form.code')"
          />
        </div>
        <div class="error" v-if="!$v.code.required && verifyPressed">
          {{ $t("verify.form.codeRequired") }}
        </div>
        <div class="error" v-else-if="$v.code.$invalid && verifyPressed">
          {{ $t("verify.form.codeValid") }}
        </div>
        <div class="button-container">
          <button type="submit" class="button-verify">
            <span v-if="loading">
              <b-spinner class="spinner-btn"></b-spinner>
              {{ $t("register.form.load") }}
            </span>
            <span v-else>
              {{ $t("verify.form.check") }}
            </span>
          </button>
        </div>
      </form>
      <div class="container-buttom-text">
        {{ $t("register.msg1") }}
        <router-link
          to="/login"
          style="text-decoration: underline;"
          class="link-bottom"
          >{{ $t("register.link1") }}</router-link
        >
      </div>
    </b-container>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="verifyConfirm"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>
<script>
import { required, integer, minLength, maxLength } from 'vuelidate/lib/validators';

import {
  AlertSetting,
  IscModalAlerts,
  mapActions,
} from "@isc/styleguide";

//Amplify.configure(awsconfig);

export default {
  name: "VerifyContainer",
  mixins: [AlertSetting],
  components: {
    IscModalAlerts
  },
  data() {
    return {
      email: "",
      code: "",
      verifyPressed: false,
      isVerify: false,
      loading: false 
    };
  },
  mounted() {
    if (window.innerWidth >= 768) {
      const heightContainer = window.innerHeight - 62;
      const $registerContainer = document.querySelector(
        ".container-verify-inner"
      );
      $registerContainer.style.height = String(heightContainer) + "px";
    }
  },
  methods: {
    ...mapActions({
      confirmationCodeAws: "login/auth/CONFIRMATION_CLIENT"
    }),
    async validateCode() {
      this.verifyPressed = true;
      try {
        if(this.$v.$invalid) {
          this.loading = false;
          return;
        }
        
        const codeValid = this.code.trim();
        const emailValid = this.email.trim();
        this.loading = true;
        
        //* CONFIRMATION CODE
        await this.confirmationCodeAws({ email: emailValid, code: codeValid });

        this.isVerify = true;
        this.successModal("User verification was successful.");
      } catch (error) {
        console.log("error confirming sign up", error);
        this.errorModal(error.response.data.message);
      } finally {
        this.loading = false; 
      }
    },
    verifyConfirm(value ){
      this.showAlert = value;
      if(this.isVerify){
        window.location.href = "/login";
        this.isVerify = false;
      }
    },
  },
  validations: {
    email: { required },
    code: { required, integer, minLength: minLength(6), maxLength: maxLength(6) }
  }
};
</script>
<style scoped>
.container-verify-inner {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title-verify {
  font-size: 52.5px;
  font-weight: 200;
  text-align: center;
}
.subtitle-verify {
  font-size: 16px;
  font-weight: 200;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.input-text {
  width: 50%;
  margin-left: 25%;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 15px 25px;
}
.button-container {
  text-align: center;
}
.button-verify {
  width: 50%;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: rgb(0, 206, 193);
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
}
.button-verify:hover {
  background-color: rgb(3, 189, 177);
}
.error {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #f44336;
}
.container-buttom-text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
}
.link-bottom {
  color: black;
}
</style>
