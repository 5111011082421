var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "container-gift", attrs: { fluid: "" } },
    [
      _c("img", {
        staticClass: "gift-login",
        attrs: { src: require("../../../public/images/login.gif") }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }