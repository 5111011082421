import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/Login.vue'
import register from '../views/Register.vue'
import verify from '../views/Verify.vue'
import forgot from '../views/Forgot.vue'
import home from '../views/Home.vue'
import error from '../views/Page404.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: login
  },
  {
    path: '/login/register',
    name: 'Register',
    component: register
  },
  {
    path: '/login/verify',
    name: 'Verify',
    component: verify
  },
  {
    path: '/login/forgot',
    name: 'Forgot',
    component: forgot
  },
  {
    path: '/home',
    name: 'Home',
    component: home
  },
  {
    path: '/error',
    name: '404',
    component: error
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
