<template>
  <main-container></main-container>
</template>

<script>
import MainContainer from '../components/login/MainContainer.vue'
export default {
  name: 'Login',
  components: {
    MainContainer
  },
  async mounted(){
    try {
    } catch (error) {
      console.error('sucedio un error en el mounted: ',error)
    }
  }
}
</script>
