var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "col-login col-remove" },
            [_c("gif-container")],
            1
          ),
          _vm.next == false
            ? _c(
                "b-col",
                { staticClass: "col-login" },
                [
                  _c("forgot-container", {
                    on: { SentEmail: _vm.nextstep, email: _vm.setEmail }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.next == true
            ? _c(
                "b-col",
                { staticClass: "col-login" },
                [_c("change-container", { attrs: { email: _vm.email } })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }