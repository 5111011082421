<template>
  <main-container></main-container>
</template>

<script>
import MainContainer from '../components/verify/MainContainer.vue'

export default {
  name: 'Verify',
  components: {
    MainContainer
  }
}
</script>
