<template>
  <div>
    <b-container fluid class="container-change-inner">
      <div class="title-change">{{ $t("forgot.change.tittle") }}</div>
      <div class="subtitle-change">{{ $t("forgot.change.subtitle") }}</div>
      <form action="" @submit.prevent="change()">
        <div>
          <input
            type="text"
            class="input-text"
            id="code"
            name="code"
            v-model="code"
            :placeholder="$t('forgot.change.form.code')"
          />
        </div>
        <div class="error" v-if="!$v.code.required && changePressed">
          {{ $t("forgot.change.form.codeRequired") }}
        </div>
        <div class="error" v-if="!$v.code.integer && changePressed">
          {{ $t("forgot.change.form.codeValid") }}
        </div>
        <div>
          <input
            type="password"
            class="input-text"
            id="newpassword"
            name="newpassword"
            v-model="newpassword"
            :placeholder="$t('forgot.change.form.newpassword')"
          />
          <i id="passwordTooltip" class="fa fa-info-circle iconInfo"></i>
          <CpTooltipDynamic
            target="passwordTooltip"
            title="passwordTooltip"
            paddingContainer="10px 5px 10px 5px"
            :message="$t('forgot.change.form.toolTipPassword')"
            :width="300"
            :height="100"
          />
        </div>
        <div
          class="error"
          v-if="!$v.newpassword.cognitopassword && changePressed"
        >
          {{ $t("forgot.change.form.goodnewPassword1") }} <br />
          {{ $t("forgot.change.form.goodnewPassword2") }}
        </div>
        <div class="error" v-if="!$v.newpassword.required && changePressed">
          {{ $t("forgot.change.form.newrequired") }}
        </div>
        <div>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            class="input-text"
            v-model="confirmPassword"
            :placeholder="$t('forgot.change.form.confirmnew')"
          />
        </div>
        <div class="error" v-if="!$v.confirmPassword.sameAs && changePressed">
          {{ $t("forgot.change.form.samePassword") }}
        </div>
        <div class="error" v-if="!$v.confirmPassword.required && changePressed">
          {{ $t("forgot.change.form.confirmnewrequired") }}
        </div>
        <div class="button-container">
          <button type="submit" class="button-change">
            <span v-if="loading">
              <b-spinner class="spinner-btn"></b-spinner>
              {{ $t("register.form.load") }}
            </span>
            <span v-else>
              {{ $t("forgot.change.form.confirm") }}
            </span>
          </button>
        </div>
      </form>
      <div class="container-buttom-text">
        {{ $t("register.msg1") }}
        <router-link
          to="/login"
          style="text-decoration: underline"
          class="link-bottom"
          >{{ $t("register.link1") }}</router-link
        >
      </div>
    </b-container>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="changeConfirm"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>
<script>
import { required, integer, sameAs, helpers } from "vuelidate/lib/validators";
import {
  AlertSetting,
  IscModalAlerts,
  CpTooltipDynamic,
  mapActions
} from "@isc/styleguide";
const cognitopassword = helpers.regex(
  "cognitopassword",
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/
);
export default {
  name: "changeContainer",
  mixins: [AlertSetting],
  components: {
    IscModalAlerts,
    CpTooltipDynamic
  },
  props: {
    email: String,
  },
  data() {
    return {
      oldpassword: "",
      newpassword: "",
      code: "",
      changePressed: false,
      sent: true,
      goodPassword: false,
      confirmPassword: "",
      isChange: false, 
      loading: false
    };
  },
  mounted() {
    if (window.innerWidth >= 768) {
      const heightContainer = window.innerHeight - 62;
      const $registerContainer = document.querySelector(
        ".container-change-inner"
      );
      $registerContainer.style.height = String(heightContainer) + "px";
    }
  },
  methods: {
    ...mapActions({
      //getInfoUser:"settings/profile/GET_INFO_USER",
      confirmationForgotPassword: "login/auth/CONFIRMATION_FORGOT_PASSWORD"
    }),
    async change() {
      this.changePressed = true;
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.loading = true;
        await this.confirmationForgotPassword({
          email: this.email,
          code: this.code,
          password: this.newpassword
        })
        this.isChange = true;
        this.successTitleModal("Your password has changed","Check your mail");
        
      } catch (error) {
        console.log("[Error] in function ChangePassword ", error);
        this.errorModal(error.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    changeConfirm(value ){
      this.showAlert = value;
      if(this.isChange){
        this.isChange = false;
        this.$router.push({ path: "/login" });
      }
    },
  },
  validations: {
    code: { required, integer },
    newpassword: { required, cognitopassword },
    confirmPassword: { required, sameAs: sameAs("newpassword") },
  },
};
</script>
<style scoped>
.container-change-inner {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title-change {
  font-size: 52.5px;
  font-weight: 200;
  text-align: center;
}
.subtitle-change {
  font-size: 16px;
  font-weight: 200;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.input-text {
  width: 50%;
  margin-left: 25%;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 15px 25px;
}
.button-container {
  text-align: center;
}
.button-change {
  width: 50%;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: rgb(0, 206, 193);
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
}
.button-change:hover {
  background-color: rgb(3, 189, 177);
}
.error {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #f44336;
}
.container-buttom-text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
}
.link-bottom {
  color: black;
}
.iconInfo{
  font-size: 14px;
  color: #5d6b88;
}
</style>
