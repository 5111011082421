var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "container-change-inner", attrs: { fluid: "" } },
        [
          _c("div", { staticClass: "title-change" }, [
            _vm._v(_vm._s(_vm.$t("forgot.change.tittle")))
          ]),
          _c("div", { staticClass: "subtitle-change" }, [
            _vm._v(_vm._s(_vm.$t("forgot.change.subtitle")))
          ]),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.change()
                }
              }
            },
            [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.code,
                      expression: "code"
                    }
                  ],
                  staticClass: "input-text",
                  attrs: {
                    type: "text",
                    id: "code",
                    name: "code",
                    placeholder: _vm.$t("forgot.change.form.code")
                  },
                  domProps: { value: _vm.code },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.code = $event.target.value
                    }
                  }
                })
              ]),
              !_vm.$v.code.required && _vm.changePressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("forgot.change.form.codeRequired")) +
                        " "
                    )
                  ])
                : _vm._e(),
              !_vm.$v.code.integer && _vm.changePressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("forgot.change.form.codeValid")) + " "
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newpassword,
                        expression: "newpassword"
                      }
                    ],
                    staticClass: "input-text",
                    attrs: {
                      type: "password",
                      id: "newpassword",
                      name: "newpassword",
                      placeholder: _vm.$t("forgot.change.form.newpassword")
                    },
                    domProps: { value: _vm.newpassword },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newpassword = $event.target.value
                      }
                    }
                  }),
                  _c("i", {
                    staticClass: "fa fa-info-circle iconInfo",
                    attrs: { id: "passwordTooltip" }
                  }),
                  _c("CpTooltipDynamic", {
                    attrs: {
                      target: "passwordTooltip",
                      title: "passwordTooltip",
                      paddingContainer: "10px 5px 10px 5px",
                      message: _vm.$t("forgot.change.form.toolTipPassword"),
                      width: 300,
                      height: 100
                    }
                  })
                ],
                1
              ),
              !_vm.$v.newpassword.cognitopassword && _vm.changePressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("forgot.change.form.goodnewPassword1")) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("forgot.change.form.goodnewPassword2")) +
                        " "
                    )
                  ])
                : _vm._e(),
              !_vm.$v.newpassword.required && _vm.changePressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("forgot.change.form.newrequired")) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.confirmPassword,
                      expression: "confirmPassword"
                    }
                  ],
                  staticClass: "input-text",
                  attrs: {
                    type: "password",
                    id: "confirmPassword",
                    name: "confirmPassword",
                    placeholder: _vm.$t("forgot.change.form.confirmnew")
                  },
                  domProps: { value: _vm.confirmPassword },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.confirmPassword = $event.target.value
                    }
                  }
                })
              ]),
              !_vm.$v.confirmPassword.sameAs && _vm.changePressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("forgot.change.form.samePassword")) +
                        " "
                    )
                  ])
                : _vm._e(),
              !_vm.$v.confirmPassword.required && _vm.changePressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("forgot.change.form.confirmnewrequired")
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  { staticClass: "button-change", attrs: { type: "submit" } },
                  [
                    _vm.loading
                      ? _c(
                          "span",
                          [
                            _c("b-spinner", { staticClass: "spinner-btn" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("register.form.load")) + " "
                            )
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("forgot.change.form.confirm")) +
                              " "
                          )
                        ])
                  ]
                )
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "container-buttom-text" },
            [
              _vm._v(" " + _vm._s(_vm.$t("register.msg1")) + " "),
              _c(
                "router-link",
                {
                  staticClass: "link-bottom",
                  staticStyle: { "text-decoration": "underline" },
                  attrs: { to: "/login" }
                },
                [_vm._v(_vm._s(_vm.$t("register.link1")))]
              )
            ],
            1
          )
        ]
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true
        },
        on: { sendResult: _vm.changeConfirm },
        model: {
          value: _vm.showAlert,
          callback: function($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }